<template>
   <div class="graphic-course">
    <div class="graphic-course-head">
      <shop-header type="graphicCourse"></shop-header>
    </div>
    <el-scrollbar height="100%">
      <div class="search-content">
      <div class="title">搜索</div>
      <el-input
      v-elInput
        v-model.lazy="formData.query"
        class="mt_32 search-input"
        placeholder="搜索教程"
        :suffix-icon="Search"
      />
      <div class="tab-list">
        <div class="tab-item" v-scaleClick v-for="item in tabList" :key="item.id" :class="[item.id === activeId ? 'active' : '']" @click="changeTab(item.id)">{{item.name}}</div>
      </div>
      <div class="total">
        总共{{computedList.length}}个搜索结果
      </div>
      <TransitionGroup
          tag="ul"
          class="search-list"
          @before-enter="onBeforeEnter"
          @enter="onEnter"
          @leave="onLeave"
        >
          <li class="item cur_p" v-for="(item,index) in computedList" @click="goLink(item)" :key="index">
            <div class="list-title" v-html="item.title"></div>
            <div class="list-content" v-html="item.desc"></div>
          </li>
      </TransitionGroup>
    </div>
    </el-scrollbar>
  </div>
</template>

<script setup>
import {getCurrentInstance,onMounted,reactive ,ref , computed} from "vue";
import ShopHeader from "@views/shop/components/shopHeader/index.vue";
import{Search} from "@element-plus/icons-vue";
//引入菜单
import MenuList from '../leftMenu/MenuList/config.js'
import {cloneDeep } from 'lodash'
import { useRouter ,useRoute} from "vue-router";

const route = useRoute()
const router = useRouter()
const newList = cloneDeep(MenuList)
const {proxy} = getCurrentInstance();
import gsap from 'gsap'
const formData = reactive({
  query: '',
})
formData.query = route.query.query

const computedList = computed(() => {
  let list = []
  if(activeId.value === 1){
    return searchMenu.filter((item,index) =>item.title.includes(formData.query) || item.desc.includes(formData.query))
  }
  if(activeId.value === 2){
    list = searchMenu.filter((item,index) => item.title.includes('手持终端'))
    return list.filter((item,index) => item.title.includes(formData.query) || item.desc.includes(formData.query))
  }
  if(activeId.value === 3){
    list = searchMenu.filter((item,index) => item.title.includes('网页端'))
    return list.filter((item,index) =>item.title.includes(formData.query) || item.desc.includes(formData.query))
  }
})
const activeId = ref(1)
let searchMenu = []
const tabList = ref([
  {
    name: '所有',
    id:1,
  },
  {
    name: '手持终端',
    id:2,
  },
  {
    name: '网页端',
    id:3,
  }
])

const changeTab = (id)=>{
  if(id === activeId.value) return
  activeId.value = id
}
function onBeforeEnter(el) {
  el.style.opacity = 0
  el.style.height = 0
}

function onEnter(el, done) {
  gsap.to(el, {
    opacity: 1,
    height: '125px',
    delay: el.dataset.index * 0.15,
    onComplete: done
  })
}

const goLink =  (e)=>{
  window.open(`/shop/graphicCourse/${e.id}`, '_blank')
}

function onLeave(el, done) {
  gsap.to(el, {
    opacity: 0,
    height: 0,
    delay: el.dataset.index * 0.15,
    onComplete: done
  })
}

function set(){
  const list = []
  for (let i = 0; i < newList.length; i++) {
    const element = newList[i];
    for (let j = 0; j < element.children.length; j++) {
      const element2 = element.children[j];
      for (let k = 0; k < element2.children.length; k++) {
        const element3 = element2.children[k];
        list.push({
          title:`${element.title}/${element2.title}/${element3.title}`,
          desc:element3.html,
          id:element2.id,
        })
      }
    }
  }
  searchMenu = list
}

set()

</script>
<style lang="scss" src="../../index.scss" scoped></style>
<style lang="scss" src="./index.scss" scoped></style>